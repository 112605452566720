import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from "../components/layout"
import {
  BelowImageTitle,
  BelowImageTitleTaglineAbove,
  BelowImageTitleTaglineBelow,
  BelowImageWrapper,
  FragmentDivider,
  ImageWrapper,
  Wrapper,
} from "../components/styles/components"
import Seo from "../components/seo"

import Grid from "@material-ui/core/Grid"

import Tagcard from "../components/tagcard"
import NoImage from "../components/image/no-image"
import Image from "../components/image/image"
import Overlay from "../components/image/overlay"

const Tagpage = ({
  location,
  data: {
    mdx,
    inTags,
    featuredImageDesktop,
    featuredImageMobile,
    avatars,
    fragments,
  },
}) => {
  const { frontmatter, fields, body } = mdx
  const {
    title,
    description,
    taglineAbove,
    taglineBelow,
    meta,
    alt,
    includesTags,
  } = frontmatter
  const { language } = fields

  return (
    <Layout>
      <Seo
        title={title}
        description={description}
        lang={language}
        meta={meta}
        location={location}
      />
      {featuredImageDesktop === null && featuredImageMobile === null ? (
        <NoImage
          title={title}
          taglineAbove={taglineAbove}
          taglineBelow={taglineBelow}
        />
      ) : (
        <React.Fragment>
          <ImageWrapper>
            <Image desktop={featuredImageDesktop} mobile={featuredImageMobile} alt={alt} />
            <Overlay
              title={title}
              taglineAbove={taglineAbove}
              taglineBelow={taglineBelow}
            />
          </ImageWrapper>
          <BelowImageWrapper>
            {taglineAbove !== null && (
              <BelowImageTitleTaglineAbove>
                {taglineAbove}
              </BelowImageTitleTaglineAbove>
            )}
            {title !== null && <BelowImageTitle>{title}</BelowImageTitle>}
            {taglineBelow !== null && (
              <BelowImageTitleTaglineBelow>
                {taglineBelow}
              </BelowImageTitleTaglineBelow>
            )}
          </BelowImageWrapper>
        </React.Fragment>
      )}

      <Wrapper>
        <MDXRenderer>{body}</MDXRenderer>
        {includesTags.map((tag, key) => {
          return (
            <React.Fragment key={tag + "-" + key}>
              <Grid container spacing={1}>
                {inTags.edges
                  .filter(elem => {
                    return elem.node.frontmatter.tags.includes(tag)
                  })
                  .map((edge, k) => {
                    const fm = edge.node.frontmatter
                    const avatar = avatars.edges.find(elem => {
                      const str = elem.node.name.concat(elem.node.ext);
                      return str === fm.avatar;
                    })
                    return (
                      <Tagcard
                        key={"unit-" + k}
                        backgroundColor={"#256EAA"}
                        name={fm.name}
                        phone={fm.phone}
                        email={fm.email}
                        degrees={fm.degrees}
                        bullets={fm.bullets}
                        slug={fm.slug}
                        avatar={avatar.node}
                        excerpt={fm.excerpt}
                        animationoffset={1000}
                        delay={(k + 1) * 0.25}
                      />
                    )
                  })}
              </Grid>
            </React.Fragment>
          );
        })}
        {fragments.edges.map((edge, key) => {
          return (
            <FragmentDivider key={"frag-mdx-" + key}>
              <MDXRenderer>{edge.node.body}</MDXRenderer>
            </FragmentDivider>
          )
        })}
      </Wrapper>
    </Layout>
  );
}

export const query = graphql`query allTags($id: String!, $langcode: String!, $includesTags: [String!], $featuredImageDesktop: String!, $featuredImageMobile: String!, $fragments: [String!]) {
  inTags: allMdx(
    filter: {fields: {language: {eq: $langcode}}, frontmatter: {tags: {in: $includesTags}}}
    sort: {fields: [frontmatter___title], order: [DESC]}
  ) {
    edges {
      node {
        fields {
          fileName
          language
        }
        id
        body
        frontmatter {
          name
          degrees
          slug
          alt
          tags
          excerpt
          avatar
          phone
          email
        }
      }
    }
  }
  featuredImageDesktop: file(relativePath: {eq: $featuredImageDesktop}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  featuredImageMobile: file(relativePath: {eq: $featuredImageMobile}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  avatars: allFile(filter: {sourceInstanceName: {eq: "images"}}) {
    edges {
      node {
        name
        ext
        childImageSharp {
          gatsbyImageData(
            width: 200
            quality: 100
            placeholder: NONE
            layout: CONSTRAINED
          )
        }
        
      }
    }
  }
  fragments: allMdx(filter: {fields: {id: {in: $fragments}}}) {
    edges {
      node {
        fields {
          fileName
          language
        }
        id
        body
        frontmatter {
          title
          slug
          tags
          excerpt
        }
      }
    }
  }
  mdx(fields: {id: {eq: $id}}) {
    fields {
      language
    }
    id
    body
    frontmatter {
      title
      taglineAbove
      taglineBelow
      description
      alt
      fragments
      includesTags
    }
  }
}
`

export default Tagpage
