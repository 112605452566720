import React, { useContext, useEffect, useRef, useState } from "react"

//* Gatsby
import { Link, navigate } from "gatsby"

//* Material UI
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Button from "@material-ui/core/Button"
import Avatar from "@material-ui/core/Avatar"
import Typography from "@material-ui/core/Typography"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"

//* i18Next
import { I18nLinksContext } from "./wrapWithI18nProvider"
import { useTranslation } from "react-i18next"

//* Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//* Gsap
import { gsap } from "gsap"

import { useWindowSize } from "../hooks/hooks"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import {GatsbyImage, getImage} from "gatsby-plugin-image";

//* Define Styles
const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 100 + "%",
  },
  cardtitle: {
    fontWeight: 500,
  },
  cardsubheader: {
    fontSize: "0.875rem",
  },
  cardcontent: {
    //* minHeight: 150
  },
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    color: "#FFFFFF",
    backgroundColor: props => props.backgroundColor,
  },
  bullets: {
    paddingTop: 10,
    paddingRight: 0,
    paddingBottom: 0,
    margin: 0,
  },
  bullet: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 0,
    paddingRight: 0,
    margin: 0,
    borderTop: "1px solid rgba(37, 110, 170, 0.1)",
    listStyleType: "none",
  },
  text: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  listText: {
    fontSize: "0.875rem",
  },
  bulletLink: {
    textDecoration: "none",
    color: "inherit",
  },
  iconbutton: {
    margin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1, 1, 1, 1),
    padding: theme.spacing(1, 2, 1, 2),
  },
}))

/*
const styles = theme => ({
  container: {
    width: "90%",
    margin: "50px auto 50px auto"
  },
  title: {
    marginBottom: 50
  },
  bookIcon: {
    width: 18,
    height: 18,
  },
  card: {
    maxWidth: 100 + "%",
  },
  actions: {
    display: 'flex',
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 30
  },
  avatar: {
    width: 60,
    height: 60,
    backgroundColor: "#256EAA",
    color: "#FFFFFF"
  },
  avatarThumbnail: {
    width: 60,
    height: 60,
  },
  button: {
    marginTop: 0,
    marginBottom: 0,
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    margin: '0 0 0 auto',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  icon: {
    margin: theme.spacing.unit,
  },
  expandIcon: {
    width: 36,
    height: 36,
  },
  cardtitle: {
    fontWeight: 500
  },

  description: {
    marginTop: 10
  }
});
*/
const styles = {
  link: {
    inactive: {
      boxShadow: "none",
      textDecoration: "none",
      color: "#256EAA",
      letterSpacing: "0px",
      textTransform: "none",
      fontSize: "100%",
    },
    active: {
      boxShadow: "none",
      textDecoration: "none",
      color: "#256EAA",
      letterSpacing: "0px",
      textTransform: "none",
      fontSize: "100%",
    },
  },
}

const fadeInFirst = delay => {
  return targets => {
    gsap.to(targets, {
      opacity: 1,
      duration: 0.75,
      delay: delay,
    })
  }
}

const fadeIn = delay => {
  return targets => {
    gsap.to(targets, {
      opacity: 1,
      duration: 0.5,
      delay: delay,
    })
  }
}

const fadeOut = delay => {
  return targets => {
    gsap.to(targets, {
      opacity: 0,
      duration: 0.25,
      delay: delay,
    })
  }
}

const Tagcard = props => {
  const { language, home } = useContext(I18nLinksContext)
  const { t } = useTranslation("tagpage")

  const size = useWindowSize()

  const [elementHeight, setElementHeight] = useState(null)
  const elementRef = useRef(null)

  const {
    name,
    degrees,
    excerpt = "",
    slug,
    bullets = null,
    phone = null,
    email = null,
    avatar,
    animationoffset = 0,
    delay = 0,
  } = props

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (
        size.height - elementHeight <= currPos.y - animationoffset &&
        size.height - elementHeight >= prevPos.y - animationoffset
      ) {
        fadeOut(delay)([elementRef.current])
      } else if (
        size.height - elementHeight >= currPos.y - animationoffset &&
        size.height - elementHeight <= prevPos.y - animationoffset
      ) {
        fadeIn(delay)([elementRef.current])
      }
    },
    [elementHeight, size],
    elementRef
  )

  useEffect(() => {
    const obj = elementRef.current.getBoundingClientRect()
    if (obj.top + obj.height - animationoffset <= size.height)
      fadeInFirst(delay)([elementRef.current])
    setElementHeight(obj.height)
  }, [animationoffset, delay, size.height])

  const handleClick = slug => {
    return event => {
      event.preventDefault()
      navigate(home[language] + slug)
    }
  }

  const classes = useStyles()

  const image = getImage(avatar);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      lg={6}
      xl={6}
      ref={elementRef}
      style={{ opacity: 0 }}
    >
      <Card className={classes.card}>
        <CardHeader
          avatar={
            avatar !== null ? (
              <Avatar
                aria-label="Publisher"
                className={classes.avatar}
                imgProps={{
                  style: {
                    margin: 0,
                  },
                }}
              >
                <GatsbyImage
                    image={image}
                    alt={avatar.name !== null ? avatar.name : ""}
                    objectFit="cover"
                    objectPosition="50% 50%"
                />
                </Avatar>
            ) : null
          }
          title={name}
          subheader={degrees}
          classes={{
            title: classes.cardtitle,
            subheader: classes.cardsubheader,
          }}
        />
        <CardContent className={classes.cardcontent}>
          <Typography variant="body2" className={classes.text} component="p">
            {excerpt}
          </Typography>
          <List className={classes.bullets}>
            {bullets !== null
              ? bullets.map((bullet, key) => {
                  return (
                    <ListItem key={"offer-" + key} className={classes.bullet}>
                      <ListItemIcon>
                        <FontAwesomeIcon
                          icon={bullet.icon}
                          size="lg"
                          color="rgba(37, 110, 170, 1)"
                          style={{ verticalAlign: "top" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        className={classes.listText}
                        disableTypography={true}
                        children={bullet.text.map((textElem, textKey) => {
                          if (textKey === 0 || textKey === bullet.text.length) {
                            return bullet.links[textKey] !== undefined ? (
                              <Link
                                key={"text-elem-" + textKey}
                                to={bullet.links[textKey]}
                                style={styles.link.inactive}
                                activeStyle={styles.link.active}
                              >
                                {textElem}
                              </Link>
                            ) : (
                              <React.Fragment key={"text-elem-" + textKey}>
                                {textElem}
                              </React.Fragment>
                            )
                          }
                          return (
                            <React.Fragment
                              key={"text-elem-" + textKey}
                            >{`, ${textElem}`}</React.Fragment>
                          )
                        })}
                      ></ListItemText>
                    </ListItem>
                  )
                })
              : null}

            {phone !== null ? (
              <ListItem className={classes.bullet} href={`tel:${phone}`}>
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={["far", "phone-office"]}
                    size="lg"
                    color="rgba(45,53,63,1)"
                    style={{ verticalAlign: "-30%" }}
                  />
                </ListItemIcon>
                <a className={classes.bulletLink} href={`tel:${phone}`}>
                  <ListItemText
                    className={classes.listText}
                    disableTypography={true}
                  >
                    {phone}
                  </ListItemText>
                </a>
              </ListItem>
            ) : null}

            {email !== null ? (
              <ListItem className={classes.bullet}>
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={["far", "envelope"]}
                    size="lg"
                    color="rgba(45,53,63,1)"
                    style={{ verticalAlign: "-30%" }}
                  />
                </ListItemIcon>
                <a className={classes.bulletLink} href={`mailto:${email}`}>
                  <ListItemText
                    className={classes.listText}
                    disableTypography={true}
                  >
                    {email}
                  </ListItemText>
                </a>
              </ListItem>
            ) : null}
          </List>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={handleClick(slug)}
          >
            {t("moreaboutme")}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
}

Tagcard.propTypes = {}

export default Tagcard
